<template>
<div class="admin-container">
    <div class="admin-container-header">
        <div class="flex justify-between">
            <h1>Кредиты/Лизинг</h1>
            <div class="flex items-center">
                <el-input placeholder="Type something" suffix-icon="el-icon-search" v-model="input2"> </el-input> 
            </div>
        </div>
    </div>
    <div class="admin-table">
        <el-table :data="tableData" style="width: 100%;" default-expand-all>
            <el-table-column prop="number" label="Number" class="table-pl-20px">
            </el-table-column>
             <el-table-column prop="type" label="Type" class="table-pl-20px">
            </el-table-column>
             <el-table-column prop="po_type" label="Po Type" class="table-pl-20px">
            </el-table-column>
             <el-table-column prop="user_c" label="1C User" class="table-pl-20px">
            </el-table-column>
            <el-table-column prop="date"  label="Aktivna" width="180">
            </el-table-column> 
            <el-table-column prop="date"  label="Aktivna" width="180">
                <template slot-scope="scope">
                    <div class="flex">
                        <p v-if="scope.row.status=='connecting'" class="c-yellow">B progress  </p>
                        <p  v-if="scope.row.status=='aktiv'" class="c-green">Aktiv  </p>
                        <p  v-if="scope.row.status=='cancel'" class="c-red">Cancel  </p>
                    </div>
                </template>
            </el-table-column> 
        </el-table>
    </div>
    <div class="admin-poginations">
        <el-pagination  :page-sizes="[10, 20, 50, 100]" :page-size="10" @current-change="paginateSize" @size-change="paginateSize" :current-page.sync="current_page" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="5">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            input2: '',
            current_page:1,
            tableData: [
                {
                    number: '12345',
                    type: 'Kredit',
                    po_type:'A1234444',
                    user_c:'N12345',
                    date:'14.02.2021-15:40',
                    status:'connecting' 
                }, 
                {
                    number: '12345',
                    type: 'Kredit',
                    po_type:'A1234444',
                    user_c:'N12345',
                    date:'14.02.2021-15:40',
                    status:'aktiv' 
                }, 
                {
                    number: '12345',
                    type: 'Kredit',
                    po_type:'A1234444',
                    user_c:'N12345',
                    date:'14.02.2021-15:40',
                    status:'cancel' 
                }, 
            ]
        }

    },
    methods:{
        paginateSize(){
            console.log("AAA")
        }
    }
}
</script>

<style>
.admin-container {
    background: var(--white-bg);
    min-height: 100%;
    width: 100%;

}

.admin-container-header {
    padding: 20px;
}

.admin-table {
    width: 100%;
}

.admin-poginations {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}
.c-yellow{
    color: rgb(197, 197, 23);
}
.c-green{
    color: rgb(13, 218, 13);
}
.c-red{
    color: red;
}
</style>
